@import 'cherre-icons.css';
@import '../../../../node_modules/react-datepicker/dist/react-datepicker.css';
@import '../../../../node_modules/graphql-voyager/dist/voyager.css';
@import '~react-image-gallery/styles/css/image-gallery.css';

.image-gallery-slides {
  background-color: rgba(0, 0, 0, 0.2);
}

// Lot page listings image gallery button.
.image-gallery-icon {
  &:hover {
    color: #df2467;
  }
}

.cm-s-default {
  height: auto !important;
}

.streetsideSdkAttributionContainer {
  display: none;
}

.mapboxgl-popup {
  z-index: 1;
  cursor: pointer;
}

.mapboxgl-popup-content {
  box-shadow: 0 2px 5px 0 #00000033 !important;
  padding: 0px !important;
  border-radius: 6px !important;
  overflow: hidden !important;

  .mapboxgl-popup-close-button {
    z-index: 999;
    width: 36px;
    height: 36px;
    padding: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background: #ffffff;
    color: #777;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 100%;
    cursor: pointer;
    font-size: 27px;
    line-height: 27px;
    outline: none;

    &:hover {
      background-color: #eee;
    }
  }
}

iframe#kw-iframe-popup {
  display: none !important;
}

.choropleth-legend {
  border: none !important;
  font-family: unset;
}

.CodeMirror {
  border-radius: 5px;
  //padding-left: 5px;
  padding-right: 5px;
  //margin: 1em 0px;
  height: auto;

  .cm-invalidchar {
    color: #eee;
  }
}

.ReactVirtualized__Table__rowColumn {
  line-height: 1.2em;
  max-height: 2.4em;
  overflow: hidden;
  white-space: normal;
  overflow-wrap: break-word;
}

.BaseTable__header-row {
  background: #fff !important;
  font-family: Montserrat !important;
}

.BaseTable__row-cell {
  font-size: 12px !important;
  font-family: Montserrat !important;
  padding: 5px 10px !important;
  font-weight: 500 !important;
}

.BaseTable__header-cell--sorting {
  border-bottom: 2px solid #000 !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  background-color: #e3e3e3;
}

::-webkit-scrollbar:vertical {
  width: 10px;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border: 2px solid #e3e3e3;
  background-color: #999;

  &:hover {
    background-color: #666;
  }
}

@media (max-width: 767px) {
  ::-webkit-scrollbar:vertical {
    width: 2px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 2px;
  }
}

::-webkit-resizer {
  display: none;
}

.reacttooltip {
  z-index: 99999 !important;
}

html {
  font-size: 14px;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: Roboto;
  color: #212121;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  padding: 0;
  margin: 0;
}

h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h1,
.h1 {
  font-size: 36px;
}

h2,
.h2 {
  font-size: 30px;
}

h3,
.h3 {
  font-size: 24px;
}

h4,
.h4 {
  font-size: 18px;
}

h5,
.h5 {
  font-size: 14px;
}

h6,
.h6 {
  font-size: 12px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #df2467;
}

#root {
  display: flex;
  flex-direction: column;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  not supported by any browser */
}

#js-searches-table-header {
  .ReactVirtualized__Grid {
    overflow: hidden !important;
  }
}

.ReactVirtualized__Grid {
  &:focus {
    outline: none;
  }
}

// Styles specific to the lot page transaction timeline.
.transaction-timeline {
  .c3-line {
    stroke-width: 4px;
  }

  .c3-axis-x text,
  .c3-axis-y text,
  .c3-tooltip {
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 500;
  }
}

// styles specific to the search for the API
em {
  text-decoration: underline;
}

.react-autosuggest__container {
  width: 100%;
  border-width: 0px;
  border-color: #ffffff;
}

.react-autosuggest__input {
  width: 100%;
  padding: 7px 7px 5px 10px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px;
  border-width: 0px;
  border-color: #ffffff;
}

.react-autosuggest__input--focused {
  outline: none;
  border-width: 0px;
  border-color: #ffffff;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-width: 0px;
  border-color: #ffffff;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  box-sizing: border-box;
  width: 100%;
  background-color: #ffffff;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  z-index: 2;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.react-autosuggest__section-title {
  padding: 10px 20px;
  font-weight: 600;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #f5f5f5;
}

.ais-Highlight-highlighted {
  background-color: rgba(255, 229, 100, 0.2);
  text-decoration: underline;
}

.graphiql-container .editorWrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.graphiql-container .doc-explorer-title,
.graphiql-container .history-title {
  flex: 1;
  font-weight: bold;
  overflow-x: visible !important;
  overflow-y: visible !important;
  padding: 10px 0 10px 10px;
  text-align: center;
  text-overflow: ellipsis;
  user-select: text;
  white-space: nowrap;
}

@media only screen and (max-width: 600px) {
  .screen-hide-xs {
    display: none;
  }
}

@media screen {
  .print-only {
    display: none !important;
  }
}

@media print {
  @page {
    size: letter portrait;
    margin: 0.3in 0.3in 0.3in 0.3in;
  }

  body {
    background: #fff;
  }

  // hide map controls
  #map-layer-selector {
    display: none !important;
  }

  .mapboxgl-ctrl {
    display: none !important;
  }

  // hide navbar
  .document_navbar {
    display: none !important;
  }

  // hide lot page top navigation panel
  .core-prospect-lot-page-nav {
    display: none !important;
  }

  // hide lot page left navigation panel
  .core-prospect-left-navigation-panel {
    display: none !important;
  }

  // hide timeline load more :double-button
  .cp-timeline-load-more {
    display: none !important;
  }

  // make sure we are not cutting panels during print
  .cp-panel {
    break-inside: avoid;
    padding: 0px 12px !important;
  }

  .cp-panel:before,
  .cp-panel:after {
    content: '';
    height: 12px;
    display: block;
    margin: -6px 0px;
    page-break-inside: avoid;
  }

  .separator {
    margin: 0px 0px 15px 0px !important;
  }

  .print-break-avoid {
    break-inside: avoid;
  }

  //edit table to fit content
  .rca-table {
    div {
      box-shadow: none;
    }

    td,
    th {
      font-size: 10px;
      padding: 12px;
    }

    th {
      font-weight: bold;
    }

    td:before,
    td:after {
      content: '';
      height: 50px;
      display: block;
      margin: -25px 0px;
      page-break-inside: avoid;
    }

    table {
      overflow: hidden;
    }
  }

  //make owner-card smaller
  .owner-card {
    width: 170px !important;
    min-width: 170px !important;
    padding: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;

    i {
      margin-right: 10px !important;
    }

    h5 {
      font-size: 10px !important;
    }

    p {
      font-size: 6px;
    }

    .print-only {
      width: 126px !important;
      font-size: 7px;
    }
  }

  // print util classes
  .print-hide {
    display: none !important;
  }

  .print-flex-100 {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }

  .print-height-auto {
    height: auto !important;
  }

  .print-static {
    position: static !important;
  }
}
