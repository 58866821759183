@font-face {
  font-family: 'cherre';
  src: url('/assets/fonts/cherre.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cherre' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .icon-ico-systemsearch:before {
  content: '\eafd';
}
.icon-ico-family:before {
  content: '\eafe';
}
.icon-ico-money:before {
  content: '\eaff';
}
.icon-ico-police:before {
  content: '\eb00';
}
.icon-ico-analytics:before {
  content: '\eb01';
}
.icon-ico-layers:before {
  content: '\eb02';
}
.icon-icon-map:before {
  content: '\eb03';
}
.icon-icon-table:before {
  content: '\eb04';
}
.icon-ico-add_to_list:before {
  content: '\eb05';
}
.icon-ico-tags:before {
  content: '\eb06';
}
.icon-Certificate:before {
  content: '\eafc';
}
.icon-ico_charts:before {
  content: '\ead0';
}
.icon-ico_navigator:before {
  content: '\ead2';
}
.icon-ico_notebook:before {
  content: '\ead3';
}
.icon-ico_settings:before {
  content: '\ead4';
}
.icon-ico-arrowleft:before {
  content: '\ead5';
}
.icon-ico-arrowright:before {
  content: '\ead6';
}
.icon-ico-bin-2:before {
  content: '\ead7';
}
.icon-ico-clear:before {
  content: '\ead9';
}
.icon-ico-close:before {
  content: '\eada';
}
.icon-ico-completed:before {
  content: '\eadb';
}
.icon-ico-dns:before {
  content: '\eadd';
}
.icon-ico-done:before {
  content: '\eade';
}
.icon-ico-down:before {
  content: '\eadf';
}
.icon-ico-dropdown:before {
  content: '\eae0';
}
.icon-ico-edit:before {
  content: '\eae1';
}
.icon-ico-group:before {
  content: '\eae2';
}
.icon-ico-inboxes:before {
  content: '\eae3';
}
.icon-ico-list:before {
  content: '\eae4';
}
.icon-ico-locked:before {
  content: '\eae5';
}
.icon-ico-menu:before {
  content: '\eae6';
}
.icon-ico-minus:before {
  content: '\eae7';
}
.icon-ico-parental:before {
  content: '\eae8';
}
.icon-ico-phonebook:before {
  content: '\eae9';
}
.icon-ico-plus:before {
  content: '\eaea';
}
.icon-ico-reset:before {
  content: '\eaeb';
}
.icon-ico-search:before {
  content: '\eaec';
}
.icon-ico-searchsettings:before {
  content: '\eaed';
}
.icon-ico-settings:before {
  content: '\eaee';
}
.icon-ico-sleft:before {
  content: '\eaef';
}
.icon-ico-sright:before {
  content: '\eaf0';
}
.icon-ico-sup:before {
  content: '\eaf1';
}
.icon-ico-Undo:before {
  content: '\eaf3';
}
.icon-ico-unlocked:before {
  content: '\eaf4';
}
.icon-ico-users:before {
  content: '\eaf6';
}
.icon-ico-zoomin:before {
  content: '\eaf7';
}
.icon-ico-zoomout:before {
  content: '\eaf8';
}
.icon-ico-zoomreset:before {
  content: '\eaf9';
}
.icon-icon-locked:before {
  content: '\eafa';
}
.icon-icon-unlocked:before {
  content: '\eafb';
}
.icon-ic_brokerages:before {
  content: '\eaca';
}
.icon-ic_customers:before {
  content: '\eacb';
}
.icon-ic_teams:before {
  content: '\eacc';
}
.icon-ic-search-settings:before {
  content: '\eacd';
}
.icon-ic-search:before {
  content: '\eace';
}
.icon-CSV_load:before {
  content: '\eac7';
}
.icon-ic_reset:before {
  content: '\e915';
}
.icon-ic_cancel:before {
  content: '\eaba';
}
.icon-ic_arrow_back:before {
  content: '\eabb';
}
.icon-ic_arrow_dd:before {
  content: '\eabc';
}
.icon-ic_calendar:before {
  content: '\eabd';
}
.icon-ic_check:before {
  content: '\eabe';
}
.icon-ic_chevron_left:before {
  content: '\eabf';
}
.icon-ic_chevron_right:before {
  content: '\eac0';
}
.icon-ic_expand_less:before {
  content: '\eac1';
}
.icon-ic_expand_more:before {
  content: '\eac2';
}
.icon-ic_more_h:before {
  content: '\eac3';
}
.icon-ic_more_v:before {
  content: '\eac4';
}
.icon-ic_refresh:before {
  content: '\eac5';
}
.icon-ic_search:before {
  content: '\eac6';
}
.icon-Minus:before {
  content: '\eab4';
}
.icon-Plus:before {
  content: '\eab5';
}
.icon-North:before {
  content: '\eab6';
}
.icon-Tilt-icon:before {
  content: '\eab7';
}
.icon-List:before {
  content: '\eab3';
}
.icon-For-Sale:before {
  content: '\eaaf';
}
.icon-Sold:before {
  content: '\eab0';
}
.icon-DOM:before {
  content: '\ea56';
}
.icon-Monthly-tax:before {
  content: '\eaac';
}
.icon-Corner-lot:before {
  content: '\eaa4';
}
.icon-Easement-type:before {
  content: '\eaa5';
}
.icon-Easement:before {
  content: '\eaa6';
}
.icon-Extension:before {
  content: '\eaa7';
}
.icon-Irregular-code:before {
  content: '\eaa8';
}
.icon-Land-use:before {
  content: '\eaa9';
}
.icon-Tags-type:before {
  content: '\eaaa';
}
.icon-Lot-zoning:before {
  content: '\eaa3';
}
.icon-Calendar:before {
  content: '\ea9c';
}
.icon-Fire-Department:before {
  content: '\ea9d';
}
.icon-Sand-clock:before {
  content: '\ea9e';
}
.icon-siamese_sprinkler:before {
  content: '\ea9f';
}
.icon-siamese_standpipe:before {
  content: '\eaa0';
}
.icon-Image-Not-Found:before {
  content: '\ea9b';
}
.icon-Julliette-balcony:before {
  content: '\ea92';
}
.icon-Full-bath:before {
  content: '\ea93';
}
.icon-Poss-baths:before {
  content: '\ea94';
}
.icon-Poss-Beds:before {
  content: '\ea95';
}
.icon-River:before {
  content: '\ea96';
}
.icon-Bathrooms:before {
  content: '\ea97';
}
.icon-Bedrooms:before {
  content: '\ea98';
}
.icon-Conditioner:before {
  content: '\ea99';
}
.icon-Half-baths:before {
  content: '\ea9a';
}
.icon-User-Info:before {
  content: '\ea91';
}
.icon-Attributes:before {
  content: '\ea8d';
}
.icon-Features:before {
  content: '\ea8e';
}
.icon-Locations:before {
  content: '\ea8f';
}
.icon-Measurement:before {
  content: '\ea90';
}
.icon-BBL:before {
  content: '\ea8b';
}
.icon-BIN:before {
  content: '\ea8c';
}
.icon-Church:before {
  content: '\ea8a';
}
.icon-Asylum:before {
  content: '\e92a';
}
.icon-Education:before {
  content: '\ea49';
}
.icon-Elevator-apartments:before {
  content: '\ea52';
}
.icon-Factory:before {
  content: '\ea57';
}
.icon-Garage-Gas:before {
  content: '\ea5a';
}
.icon-Garage:before {
  content: '\ea5b';
}
.icon-Government:before {
  content: '\ea5c';
}
.icon-Hospital:before {
  content: '\ea5d';
}
.icon-Hotel:before {
  content: '\ea5e';
}
.icon-Loft:before {
  content: '\ea5f';
}
.icon-Multi-Family:before {
  content: '\ea60';
}
.icon-Office:before {
  content: '\ea61';
}
.icon-Outdoor:before {
  content: '\ea62';
}
.icon-Public-Assembly:before {
  content: '\ea63';
}
.icon-Rentals:before {
  content: '\ea64';
}
.icon-Retail:before {
  content: '\ea65';
}
.icon-Single-Family:before {
  content: '\ea66';
}
.icon-Theatre:before {
  content: '\ea67';
}
.icon-Transportation:before {
  content: '\ea68';
}
.icon-Two-Family:before {
  content: '\ea69';
}
.icon-Utility:before {
  content: '\ea6a';
}
.icon-Vacant-land:before {
  content: '\ea6b';
}
.icon-Walkup:before {
  content: '\ea6d';
}
.icon-Warehouse:before {
  content: '\ea6f';
}
.icon-tab-cma:before {
  content: '\ea72';
}
.icon-tab-documents:before {
  content: '\ea87';
}
.icon-tasks:before {
  content: '\ea89';
}
.icon-storage:before {
  content: '\ea50';
}
.icon-basement-type:before {
  content: '\ea34';
}
.icon-bld-converted:before {
  content: '\ea35';
}
.icon-bld-incorporated:before {
  content: '\ea36';
}
.icon-concierge:before {
  content: '\ea38';
}
.icon-fire-protection:before {
  content: '\ea39';
}
.icon-flip-tax:before {
  content: '\ea3a';
}
.icon-info-tip:before {
  content: '\ea3b';
}
.icon-intercom:before {
  content: '\ea3c';
}
.icon-map-location:before {
  content: '\ea3d';
}
.icon-new-contruction:before {
  content: '\ea3e';
}
.icon-pied-a-terre:before {
  content: '\ea3f';
}
.icon-year-built:before {
  content: '\ea40';
}
.icon-am-balcony:before {
  content: '\ea41';
}
.icon-am-courtyard:before {
  content: '\ea42';
}
.icon-am-doorman:before {
  content: '\ea43';
}
.icon-am-fitness:before {
  content: '\ea44';
}
.icon-am-garage:before {
  content: '\ea45';
}
.icon-am-garden:before {
  content: '\ea46';
}
.icon-am-laundry:before {
  content: '\ea47';
}
.icon-am-lobby:before {
  content: '\ea48';
}
.icon-am-parkingtype:before {
  content: '\ea4a';
}
.icon-am-parkingvalet:before {
  content: '\ea4b';
}
.icon-am-patio:before {
  content: '\ea4c';
}
.icon-am-pool:before {
  content: '\ea4d';
}
.icon-am-roofdeck:before {
  content: '\ea4e';
}
.icon-am-sauna:before {
  content: '\ea4f';
}
.icon-am-spa:before {
  content: '\ea51';
}
.icon-am-steamroom:before {
  content: '\ea53';
}
.icon-am-super:before {
  content: '\ea54';
}
.icon-am-terrace:before {
  content: '\ea55';
}
.icon-am-unit:before {
  content: '\eaad';
}
.icon-am-yoga:before {
  content: '\ea58';
}
.icon-bld-area:before {
  content: '\ea59';
}
.icon-bld-depth:before {
  content: '\ea6c';
}
.icon-bld-height:before {
  content: '\ea6e';
}
.icon-bld-width:before {
  content: '\ea70';
}
.icon-elevator:before {
  content: '\ea73';
}
.icon-highraise:before {
  content: '\ea74';
}
.icon-house:before {
  content: '\ea75';
}
.icon-pets:before {
  content: '\ea76';
}
.icon-trans-bike:before {
  content: '\ea77';
}
.icon-trans-bus:before {
  content: '\ea78';
}
.icon-trans-school:before {
  content: '\ea79';
}
.icon-trans-subway:before {
  content: '\ea7a';
}
.icon-type-condomium:before {
  content: '\ea7b';
}
.icon-type-condop:before {
  content: '\ea7c';
}
.icon-type-coop:before {
  content: '\ea7d';
}
.icon-type-recentdev:before {
  content: '\ea7f';
}
.icon-type-rentals:before {
  content: '\ea80';
}
.icon-type-VWI:before {
  content: '\ea81';
}
.icon-type-VWII:before {
  content: '\ea82';
}
.icon-walkup:before {
  content: '\ea83';
}
.icon-wall:before {
  content: '\ea84';
}
.icon-wheelchair:before {
  content: '\ea85';
}
.icon-year-altered:before {
  content: '\ea86';
}
.icon-icon_bell:before {
  content: '\ea2f';
}
.icon-icon_document:before {
  content: '\ea30';
}
.icon-icon_navigator:before {
  content: '\ea31';
}
.icon-icon_order:before {
  content: '\ea32';
}
.icon-icon_todo:before {
  content: '\ea33';
}
.icon-pg-textoverimage:before {
  content: '\ea2d';
}
.icon-pg-blank:before {
  content: '\ea1a';
}
.icon-pg-brokerage:before {
  content: '\ea1d';
}
.icon-pg-cc:before {
  content: '\ea1e';
}
.icon-pg-cols:before {
  content: '\ea1f';
}
.icon-pg-cover:before {
  content: '\ea20';
}
.icon-pg-image:before {
  content: '\ea2e';
}
.icon-pg-custom:before {
  content: '\ea21';
}
.icon-pg-featimage:before {
  content: '\ea22';
}
.icon-pg-gallery:before {
  content: '\ea23';
}
.icon-pg-imgtxt:before {
  content: '\ea24';
}
.icon-pg-inventory:before {
  content: '\ea25';
}
.icon-pg-ngb:before {
  content: '\ea26';
}
.icon-pg-team:before {
  content: '\ea27';
}
.icon-pg-testimonials:before {
  content: '\ea28';
}
.icon-pg-text:before {
  content: '\ea29';
}
.icon-pg-thank:before {
  content: '\ea2a';
}
.icon-pg-timeline:before {
  content: '\ea2b';
}
.icon-pg-zebra:before {
  content: '\ea2c';
}
.icon-align-center:before {
  content: '\ea0a';
}
.icon-align-justify:before {
  content: '\ea0f';
}
.icon-align-left:before {
  content: '\ea10';
}
.icon-align-right:before {
  content: '\ea11';
}
.icon-bold:before {
  content: '\ea12';
}
.icon-hidden:before {
  content: '\ea13';
}
.icon-italic:before {
  content: '\ea14';
}
.icon-unordered:before {
  content: '\ea15';
}
.icon-ordered:before {
  content: '\ea16';
}
.icon-redo:before {
  content: '\ea17';
}
.icon-strikethrough:before {
  content: '\ea18';
}
.icon-underline:before {
  content: '\ea19';
}
.icon-undo:before {
  content: '\ea1b';
}
.icon-visible:before {
  content: '\ea1c';
}
.icon-call:before {
  content: '\ea0b';
}
.icon-email-1:before {
  content: '\ea0c';
}
.icon-location:before {
  content: '\ea0d';
}
.icon-brokerage:before {
  content: '\ea02';
}
.icon-password:before {
  content: '\ea03';
}
.icon-personal:before {
  content: '\ea04';
}
.icon-settings-1:before {
  content: '\ea05';
}
.icon-team-1:before {
  content: '\ea06';
}
.icon-terms:before {
  content: '\ea09';
}
.icon-new-development:before {
  content: '\ea01';
}
.icon-analytics:before {
  content: '\e9f7';
}
.icon-buyer:before {
  content: '\e9f8';
}
.icon-corporate:before {
  content: '\e9f9';
}
.icon-landlord:before {
  content: '\e9fa';
}
.icon-postcard:before {
  content: '\e9fb';
}
.icon-listing-presentation:before {
  content: '\e9fc';
}
.icon-rent:before {
  content: '\e9fd';
}
.icon-sale:before {
  content: '\e9fe';
}
.icon-search-large:before {
  content: '\e9ff';
}
.icon-showsheet:before {
  content: '\ea00';
}
.icon-office:before {
  content: '\e9f4';
}
.icon-bag:before {
  content: '\e9f5';
}
.icon-presentation:before {
  content: '\e9f6';
}
.icon-dollar-down1:before {
  content: '\e9f2';
}
.icon-dollar-down2:before {
  content: '\e9f3';
}
.icon-equalhousinglender:before {
  content: '\e9f0';
}
.icon-recycle:before {
  content: '\e9f1';
}
.icon-synchronize:before {
  content: '\e9ed';
}
.icon-pencil:before {
  content: '\e9ee';
}
.icon-appartment-01:before {
  content: '\e9e4';
}
.icon-appartment-02:before {
  content: '\e9e5';
}
.icon-appartment-03:before {
  content: '\e9e6';
}
.icon-appartment-04:before {
  content: '\e9e7';
}
.icon-appartment-05:before {
  content: '\e9e8';
}
.icon-appartment-06:before {
  content: '\e9e9';
}
.icon-collaboration:before {
  content: '\e9ea';
}
.icon-dollar-up1:before {
  content: '\e9eb';
}
.icon-dollar-up2:before {
  content: '\e9ec';
}
.icon-hands:before {
  content: '\e9dd';
}
.icon-house-dollar:before {
  content: '\e9e1';
}
.icon-ny-appartments:before {
  content: '\e9e2';
}
.icon-rules:before {
  content: '\e9e3';
}
.icon-vertical-dots:before {
  content: '\e9dc';
}
.icon-zoom-in2:before {
  content: '\e9de';
}
.icon-zoom-out:before {
  content: '\e9df';
}
.icon-bell:before {
  content: '\e9e0';
}
.icon-user:before {
  content: '\e9ef';
}
.icon-real-estate-house-measurements:before {
  content: '\e9da';
}
.icon-Postcard-final:before {
  content: '\e9db';
}
.icon-small-building:before {
  content: '\e9d9';
}
.icon-pencil-ruler:before {
  content: '\e9d5';
}
.icon-rooms:before {
  content: '\e9d6';
}
.icon-hotel-bed-1:before {
  content: '\e9d7';
}
.icon-hotel-bath-shower:before {
  content: '\e9d8';
}
.icon-template22:before {
  content: '\e9ce';
}
.icon-template23:before {
  content: '\e9cf';
}
.icon-template24:before {
  content: '\e9d0';
}
.icon-template25:before {
  content: '\e9d1';
}
.icon-template26:before {
  content: '\e9d2';
}
.icon-template27:before {
  content: '\e9d3';
}
.icon-template28:before {
  content: '\e9d4';
}
.icon-done:before {
  content: '\e9c9';
}
.icon-instagram:before {
  content: '\e928';
}
.icon-linkedin:before {
  content: '\e935';
}
.icon-operator-male:before {
  content: '\e914';
}
.icon-building-6:before {
  content: '\e9aa';
}
.icon-rewards-pedestal:before {
  content: '\e9c7';
}
.icon-warehouse-box:before {
  content: '\e9c8';
}
.icon-user-add:before {
  content: '\e900';
}
.icon-ButtonHit:before {
  content: '\e901';
}
.icon-Chart:before {
  content: '\e902';
}
.icon-HandKey:before {
  content: '\e903';
}
.icon-MoneyCoins:before {
  content: '\e904';
}
.icon-air-conditioning:before {
  content: '\e905';
}
.icon-angel:before {
  content: '\e906';
}
.icon-balcony:before {
  content: '\e908';
}
.icon-bar-menu:before {
  content: '\e909';
}
.icon-bathroom:before {
  content: '\e90a';
}
.icon-bedroom:before {
  content: '\e90b';
}
.icon-bicycle:before {
  content: '\e90c';
}
.icon-board-approval:before {
  content: '\e90d';
}
.icon-book:before {
  content: '\e90e';
}
.icon-business-report-with-growth:before {
  content: '\e90f';
}
.icon-calendar-period:before {
  content: '\e910';
}
.icon-calendar:before {
  content: '\e911';
}
.icon-camera:before {
  content: '\e912';
}
.icon-chat:before {
  content: '\e913';
}
.icon-cherre-logo:before {
  content: '\e916';
}
.icon-cloud:before {
  content: '\e918';
}
.icon-comps:before {
  content: '\e919';
}
.icon-courtyard:before {
  content: '\e91a';
}
.icon-deck:before {
  content: '\e91c';
}
.icon-delete:before {
  content: '\e91d';
}
.icon-description:before {
  content: '\e91e';
}
.icon-dining-room:before {
  content: '\e91f';
}
.icon-dishwasher:before {
  content: '\e920';
}
.icon-dollar-price-tag:before {
  content: '\e921';
}
.icon-doorman:before {
  content: '\e922';
}
.icon-drag-and-drop:before {
  content: '\e923';
}
.icon-drawable-arrow:before {
  content: '\e924';
}
.icon-dropbox-logo:before {
  content: '\e925';
}
.icon-dropdown:before {
  content: '\e926';
}
.icon-duplex:before {
  content: '\e927';
}
.icon-eco-energy-car:before {
  content: '\e929';
}
.icon-edit:before {
  content: '\e92b';
}
.icon-elevator1:before {
  content: '\e92c';
}
.icon-email:before {
  content: '\e92d';
}
.icon-embed:before {
  content: '\e92e';
}
.icon-export:before {
  content: '\e92f';
}
.icon-eye:before {
  content: '\e930';
}
.icon-facebook-symbol:before {
  content: '\e931';
}
.icon-features-property:before {
  content: '\e932';
}
.icon-filter:before {
  content: '\e933';
}
.icon-fireplace:before {
  content: '\e934';
}
.icon-flag:before {
  content: '\e936';
}
.icon-floorplan:before {
  content: '\e937';
}
.icon-flow-graph:before {
  content: '\e938';
}
.icon-ft:before {
  content: '\e939';
}
.icon-full-view:before {
  content: '\e93a';
}
.icon-furnished:before {
  content: '\e93b';
}
.icon-garage-parking:before {
  content: '\e93c';
}
.icon-garbage:before {
  content: '\e93d';
}
.icon-garden:before {
  content: '\e93e';
}
.icon-gmail:before {
  content: '\e93f';
}
.icon-google-plus-symbol:before {
  content: '\e940';
}
.icon-green-building:before {
  content: '\e941';
}
.icon-grid-view:before {
  content: '\e942';
}
.icon-gym:before {
  content: '\e943';
}
.icon-home-default:before {
  content: '\e944';
}
.icon-house1:before {
  content: '\e945';
}
.icon-imac:before {
  content: '\e946';
}
.icon-increase-size:before {
  content: '\e947';
}
.icon-information-circular-button:before {
  content: '\e948';
}
.icon-ipad:before {
  content: '\e949';
}
.icon-ipdf:before {
  content: '\e94a';
}
.icon-iphone:before {
  content: '\e94b';
}
.icon-laundry-in-building:before {
  content: '\e94c';
}
.icon-like:before {
  content: '\e94d';
}
.icon-link-symbol:before {
  content: '\e94e';
}
.icon-list-view:before {
  content: '\e94f';
}
.icon-list-with-dots:before {
  content: '\e950';
}
.icon-list-with-numbers:before {
  content: '\e951';
}
.icon-list-with-square:before {
  content: '\e952';
}
.icon-live-work-space:before {
  content: '\e953';
}
.icon-load:before {
  content: '\e954';
}
.icon-loft:before {
  content: '\e955';
}
.icon-logout:before {
  content: '\e956';
}
.icon-long-arrow-pointing-up:before {
  content: '\e957';
}
.icon-luxury-home:before {
  content: '\e958';
}
.icon-magic-wand:before {
  content: '\e959';
}
.icon-map:before {
  content: '\e95a';
}
.icon-market-research:before {
  content: '\e95b';
}
.icon-messsanger:before {
  content: '\e95c';
}
.icon-more:before {
  content: '\e95d';
}
.icon-move:before {
  content: '\e95e';
}
.icon-multiple-levels:before {
  content: '\e95f';
}
.icon-neighborhood:before {
  content: '\e960';
}
.icon-new-york-panoramic-buildings:before {
  content: '\e961';
}
.icon-news:before {
  content: '\e962';
}
.icon-no-fee:before {
  content: '\e963';
}
.icon-notebooks:before {
  content: '\e964';
}
.icon-ns-about-team:before {
  content: '\e965';
}
.icon-ns-about-the-company:before {
  content: '\e966';
}
.icon-ns-awards:before {
  content: '\e967';
}
.icon-ns-closing-costs:before {
  content: '\e968';
}
.icon-ns-inventory:before {
  content: '\e969';
}
.icon-ns-list:before {
  content: '\e96a';
}
.icon-ns-marketing-plan:before {
  content: '\e96b';
}
.icon-ns-neighborhood:before {
  content: '\e96c';
}
.icon-ns-past-listing-map:before {
  content: '\e96d';
}
.icon-ns-property-description:before {
  content: '\e96e';
}
.icon-ns-property-images:before {
  content: '\e96f';
}
.icon-ns-statistics:before {
  content: '\e970';
}
.icon-ns-team-members:before {
  content: '\e971';
}
.icon-ns-testimonials:before {
  content: '\e972';
}
.icon-ns-welcome:before {
  content: '\e973';
}
.icon-office365:before {
  content: '\e974';
}
.icon-opened-door-aperture:before {
  content: '\e975';
}
.icon-outdoor-space:before {
  content: '\e976';
}
.icon-padlock:before {
  content: '\e977';
}
.icon-paper-clip:before {
  content: '\e978';
}
.icon-park:before {
  content: '\e979';
}
.icon-parking:before {
  content: '\e97a';
}
.icon-patio:before {
  content: '\e97b';
}
.icon-pdf-export:before {
  content: '\e97c';
}
.icon-pdf-preview:before {
  content: '\e97d';
}
.icon-pdf:before {
  content: '\e97e';
}
.icon-penthouse:before {
  content: '\e97f';
}
.icon-pets1:before {
  content: '\e980';
}
.icon-phone:before {
  content: '\e981';
}
.icon-photographers:before {
  content: '\e982';
}
.icon-photos:before {
  content: '\e983';
}
.icon-picture:before {
  content: '\e984';
}
.icon-pied-a-terre1:before {
  content: '\e985';
}
.icon-pin:before {
  content: '\e986';
}
.icon-pinterest-symbol:before {
  content: '\e987';
}
.icon-pitchpack:before {
  content: '\e988';
}
.icon-plan:before {
  content: '\e989';
}
.icon-plus:before {
  content: '\e98a';
}
.icon-pre-war:before {
  content: '\e98b';
}
.icon-printer:before {
  content: '\e98c';
}
.icon-profile:before {
  content: '\e98d';
}
.icon-property:before {
  content: '\e98e';
}
.icon-public-transport-subway:before {
  content: '\e98f';
}
.icon-push-pin:before {
  content: '\e990';
}
.icon-question:before {
  content: '\e991';
}
.icon-reject:before {
  content: '\e992';
}
.icon-reset:before {
  content: '\e993';
}
.icon-resize-tool:before {
  content: '\e994';
}
.icon-right-arrow:before {
  content: '\e995';
}
.icon-river:before {
  content: '\e996';
}
.icon-roof-deck:before {
  content: '\e997';
}
.icon-roof-garden:before {
  content: '\e998';
}
.icon-save:before {
  content: '\e999';
}
.icon-search-file:before {
  content: '\e99a';
}
.icon-search:before {
  content: '\e99b';
}
.icon-send-msg:before {
  content: '\e99c';
}
.icon-shopping-cart:before {
  content: '\e99f';
}
.icon-show-apps-button:before {
  content: '\e9a0';
}
.icon-signature:before {
  content: '\e9a1';
}
.icon-smoke:before {
  content: '\e9a2';
}
.icon-star:before {
  content: '\e9a3';
}
.icon-storage-available:before {
  content: '\e9a4';
}
.icon-suitcase:before {
  content: '\e9a5';
}
.icon-swimming-pool:before {
  content: '\e9a6';
}
.icon-table-view:before {
  content: '\e9a7';
}
.icon-tax-abate:before {
  content: '\e9a8';
}
.icon-team:before {
  content: '\e9a9';
}
.icon-template1:before {
  content: '\e9ab';
}
.icon-template2:before {
  content: '\e9ac';
}
.icon-template3:before {
  content: '\e9ad';
}
.icon-template4:before {
  content: '\e9ae';
}
.icon-template5:before {
  content: '\e9af';
}
.icon-template6:before {
  content: '\e9b0';
}
.icon-template7:before {
  content: '\e9b1';
}
.icon-template8:before {
  content: '\e9b2';
}
.icon-template9:before {
  content: '\e9b3';
}
.icon-template10:before {
  content: '\e9b4';
}
.icon-template11:before {
  content: '\e9b5';
}
.icon-template12:before {
  content: '\e9b6';
}
.icon-template13:before {
  content: '\e9b7';
}
.icon-template14:before {
  content: '\e9b8';
}
.icon-template15:before {
  content: '\e9b9';
}
.icon-template16:before {
  content: '\e9ba';
}
.icon-template17:before {
  content: '\e9bb';
}
.icon-template18:before {
  content: '\e9bc';
}
.icon-template19:before {
  content: '\e9bd';
}
.icon-template21:before {
  content: '\e9bf';
}
.icon-terrace:before {
  content: '\e9c0';
}
.icon-time-watch:before {
  content: '\e9c1';
}
.icon-twitter-symbol:before {
  content: '\e9c2';
}
.icon-typewriter:before {
  content: '\e9c3';
}
.icon-vacation-home:before {
  content: '\e9c4';
}
.icon-verizon-fios:before {
  content: '\e9c5';
}
.icon-warning:before {
  content: '\e9c6';
}
.icon-washer:before {
  content: '\e9ca';
}
.icon-web:before {
  content: '\e9cb';
}
.icon-weekly-report:before {
  content: '\e9cc';
}
.icon-windows-logo:before {
  content: '\e9cd';
}
.icon-youtube:before {
  content: '\eab8';
}
.icon-zoom-in:before {
  content: '\eab9';
} */

.icon-API:before {
  content: '\eb07';
}

.icon-email-1:before {
  content: '\ea0c';
}

.icon-icon-map:before {
  content: '\eb03';
}

.icon-email:before {
  content: '\e92d';
}

.icon-phone:before {
  content: '\e981';
}

.icon-account:before {
  content: '\ea07';
}
.icon-arrow:before {
  content: '\e907';
}
.icon-Bookmark:before {
  content: '\ead8';
}
.icon-certificate:before {
  content: '\ea37';
}
.icon-close:before {
  content: '\e917';
}
.icon-CSV-file:before {
  content: '\eac8';
}
.icon-exit:before {
  content: '\ea08';
}
.icon-ic_customers:before {
  content: '\eacb';
}
.icon-ico-dashboard:before {
  content: '\eadc';
}
.icon-ico_documents:before {
  content: '\ead1';
}
.icon-icon_file:before {
  content: '\eac9';
}
.icon-ico-sync:before {
  content: '\eaf2';
}
.icon-ico-voyager:before {
  content: '\eacf';
}
.icon-ico-useremulate:before {
  content: '\eaf5';
}
.icon-Lot-area:before {
  content: '\eaab';
}
.icon-Lot-depth:before {
  content: '\eaa1';
}
.icon-Lot-front:before {
  content: '\eaa2';
}
.icon-Map-layers:before {
  content: '\eaae';
}
.icon-Map-location-2:before {
  content: '\eab1';
}
.icon-notifications:before {
  content: '\ea71';
}
.icon-share:before {
  content: '\e99e';
}
.icon-settings:before {
  content: '\e99d';
}
.icon-tab-navigator:before {
  content: '\ea88';
}
.icon-type-newdev:before {
  content: '\ea7e';
}
.icon-user-1:before {
  content: '\ea0e';
}
.icon-User:before {
  content: '\e9be';
}
.icon-Settings-2:before {
  content: '\eab2';
}
.icon-ico-dashboards:before {
  content: '\eb08';
}
